
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  nextTick,
  watch,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { getDropdownsByType } from "@/api/dropdown.api";
import {
  addPayment,
  addPaymentRefundItemRequest,
  searchPaymentExtV2,
} from "@/api/payment.api";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import { reversePayment } from "@/api/payment.api";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import useFilters from "../common/useFilters";
import debounce from "lodash.debounce";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: number;
  description: string;
}
export interface Data {
  emptyListError?: string;
  isLoaded: boolean;
  expandedAll: boolean;
  items: any[];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  filter: {
    dateType: number;
    startDate: Date | string | null;
    endDate: Date | string | null;
    providerId: string | null;
    transactionType?: number[] | null;
    paymentType?: number[] | null;
    adjustmentType?: number[] | null;
  };
  dict: {
    datesList: { keyword: number; name: string }[];
    boolList: { code?: boolean; name: string }[];
    paymentTypes: StatusCode[];
    adjustmentReasonCodes: any[];
    transactionTypes: StatusCode[];
    patientPayers: { payerId: string; payerName: string }[];
    actualPaymentTypes: StatusCode[];
    actualTransactionTypes: StatusCode[];
    actualPatientPayers: { payerId: string; payerName: string }[];
  };
  showInactive: boolean;
}
export default defineComponent({
  name: "PatientTransactions",
  props: ["patientId"],
  components: { Multiselect, PaginationUi, DateFloatComponent },
  setup(props, ctx) {
    const store = useStore();
    const router = useRouter();
    const { putFiltersToUrl, getFiltersFromUrl } = useFilters();

    const orderList = [
      {
        name: "Payment/Refund Date",
        key: "paymentDate",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Transaction ID",
        key: "paymentId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Entry Date",
        key: "entryDate",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Payer",
        key: "payer.name",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Transaction Type",
        key: "type",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Payment Type",
        key: "paymentType",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Adjustment Type",
        key: "adjustmentReason",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Ref #",
        key: "reference",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Amount",
        key: "totalAmount",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Amount Applied",
        key: "amountApplied",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Unapplied Credit Balance",
        key: "unappliedCredit",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Notes",
        key: null,
        isFilter: true,
        keyword: null,
        formType: "text",
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      isLoaded: false,
      items: [],
      expandedAll: false,
      keyword: "",
      orderBy: "paymentId",
      filter: {
        dateType: 1,
        startDate: null,
        endDate: null,
        providerId: null,
        transactionType: [],
        paymentType: [],
        adjustmentType: [],
      },
      IsAdvancedOrderBy: false,
      isDecr: true,
      showInactive: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        datesList: [
          { keyword: 1, name: "Entry Date" },
          { keyword: 2, name: "Payment Date" },
        ],
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
          { code: undefined, name: "All" },
        ],
        paymentTypes: [],
        transactionTypes: [],
        adjustmentReasonCodes: [],
        patientPayers: [],
        actualPaymentTypes: [],
        actualTransactionTypes: [],
        actualPatientPayers: [],
      },
    });

    async function cleanFilter() {
      data.filter = {
        dateType: 1,
        startDate: null,
        endDate: null,
        providerId: null,
        transactionType: [],
        paymentType: [],
        adjustmentType: [],
      };
      data.keyword = "";
      data.orderBy = "paymentId";
      data.IsAdvancedOrderBy = false;
      data.isDecr = true;
      orderList.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      getFiltersFromUrl();
      organizationId.value = getOrganization();
      let adjustmentReasonCodes = await getDropdownsByType({
        type: "Adjustment Types",
      });
      data.dict.adjustmentReasonCodes = adjustmentReasonCodes.data;
      data.dict.paymentTypes = store.getters.allPaymentTypes;
      data.dict.transactionTypes = store.getters.allTransactionTypes;

      await getAll();
      data.isLoaded = true;
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      data.expandedAll = false;
      putFiltersToUrl();

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.map((item) => {
            return item.key;
          }),
          keyword: data.keyword,
        },
        PatientId: props.patientId,
        TransactionType: data.filter.transactionType,
        DateType: data.filter.dateType,
        ProviderId: data.filter.providerId,
        StartDate: data.filter.startDate,
        EndDate: data.filter.endDate,
        PaymentType: data.filter.paymentType,
        AdjustmentType: data.filter.adjustmentType,
      };

      const res = await searchPaymentExtV2(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      data.emptyListError = undefined;
      if (data.items.length == 0) {
        const currentFilters = Object.keys(data.filter).filter((f) => {
          return (
            data.filter[f] != undefined &&
            data.filter[f] !== "" &&
            data.filter[f] != null
          );
        });
        if (currentFilters.length > 0 || data.keyword) {
          data.emptyListError =
            "**No results match your filter criteria. Please clear all filters to view list";
        } else {
          data.emptyListError = "List is Empty";
        }
      }
    }

    function selectItem(item) {
      //router.push({ path: "/patientPayment/" + item.id + "/", query: { patientId: props.patientId } });
    }

    function newPayment() {
      router.push({ path: "patientPayment" });
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    const transactionType = { 0: "payment", 1: "adjustment" };

    async function reverse(payment, serviceItem) {
      let title =
        "Are you sure you want to reverse this " +
        transactionType[payment.type] +
        "?";
      Swal.fire({
        title: title,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Apply",
        denyButtonText: "Cancel",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const request = {
            id: payment.id,
            entryDate: getUTCnow().toISOString(),
            serviceItemIds: [serviceItem.id],
          };
          await reversePayment(request);
          await getAll();
          ctx.emit("refreshPatient");
          Swal.fire("Ok!", "Transaction has been reversed", "success");
        }
      });
    }

    async function autoRefund(item) {
      Swal.fire({
        title: "Are you sure you want to Refund Credits?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          let refund = {
            id: "",
            type: 2,
            paymentType: 2,
            adjustmentReason: 0,
            entryDate: getUTCnow().toISOString(),
            paymentDate: getUTCnow().toISOString(),
            accountingDate: getUTCnow().toISOString(),
            unallocatedAmount: 0,
            totalAmount: item.unallocatedAmount,
            patientId: props.patientId,
            unappliedCredit: null,
          };

          const res = (await addPayment(refund)) as any;
          const paymentId = res.data;
          const itemToPost = {
            RefundAmount: item.unallocatedAmount,
            Id: item.id,
            PaymentId: paymentId,
          };
          await addPaymentRefundItemRequest({
            entryDate: getUTCnow().toISOString(),
            paymentId: paymentId,
            items: [itemToPost],
          });

          await getAll();

          Swal.fire("Ok!", item.paymentId + " has been refunded", "success");
        }
      });
    }

    function getUTCnow() {
      var date = new Date();
      const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return d;
    }

    const divs = ref([]);

    function expand(item) {
      item.expanded = !item.expanded;
    }

    watch(
      () => data.expandedAll,
      async () => {
        data.items.forEach((item: any) => {
          item.expanded = data.expandedAll;
        });
      }
    );

    function applyPayment(item) {
      router.push({
        path: "/patientPayment/" + item.id + "/",
        query: { patientId: item.patientId },
      });
    }

    return {
      reverse,
      organizationId,
      clearSearch,
      data,
      selectItem,
      orderList,
      newPayment,
      debounceSearch,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      autoRefund,
      applyPayment,
      expand,
      divs,
    };
  },
});
